import React, { useEffect, useRef } from 'react';
import './Portfolio.css'; // Import pliku CSS
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';
import img4 from '../images/img4.png';

const Portfolio = () => {
  const headerRef = useRef(null);
  const subtitleRef = useRef(null);
  const cardsRef = useRef([]);

  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate'); // Dodanie klasy animacji
          observer.unobserve(entry.target); // Przestajemy obserwować po animacji
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.2, // Animacja uruchamia się przy 20% widoczności
    });

    // Obserwuj header, subtitle oraz karty
    if (headerRef.current) observer.observe(headerRef.current);
    if (subtitleRef.current) observer.observe(subtitleRef.current);

    cardsRef.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      observer.disconnect(); // Czyszczenie obserwatora po unmount
    };
  }, []);

  return (
    <section id="portfolio" className="portfolio">
      <div className="portfolio-container">
        <div className="portfolio-header">
          <h2 ref={headerRef} className="portfolio-title">
            Nasze portfolio
          </h2>
          <p ref={subtitleRef} className="portfolio-subtitle">
            Przekonaj się, jak nasze podejście dostarcza realnej wartości,
            wprowadzając innowacje i usprawniając codzienne operacje.
          </p>
        </div>
        <div className="portfolio-cards">
          {[
            {
              title: 'xTemplater',
              description:
                'Narzędzie do tworzenia dynamicznych formularzy powiązanych z plikami Word, idealne do automatyzacji dokumentów.',
              image: img4,
              link: 'https://xtemplater.com',
            },
            {
              title: 'Otwarte Sprawozdania',
              description:
                'Platforma do analizy sprawozdań finansowych polskich firm, umożliwiająca wyodrębnianie kluczowych danych.',
              image: img3,
              link: 'https://otwartesprawozdania.pl',
            },
            {
              title: 'Kancelaria 4.0',
              description:
                'Platforma e-learningowa dla prawników, ucząca wykorzystania sztucznej inteligencji w codziennej praktyce.',
              image: img2,
              link: 'https://kancelaria40.pl',
            }
          ].map((card, index) => (
            <div
              key={index}
              className="portfolio-card"
              ref={(el) => (cardsRef.current[index] = el)} // Przypisanie refs
            >
              <h3 className="portfolio-card-title">{card.title}</h3>
              <p className="portfolio-card-description">{card.description}</p>
              <img src={card.image} alt={card.title} className="portfolio-card-image" />
              <a href={card.link} target="_blank" className="portfolio-card-link">
                Zobacz projekt
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
