import React, { useEffect, useRef } from 'react';
import './AboutUs.css';

const AboutUs = () => {
  const valuesRef = useRef(null);
  const quoteRef = useRef(null);
  const prioritiesRef = useRef(null);
  const statsRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target); // Stop observing after animation triggers
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.4, // Trigger when 30% of the element is visible
    });

    const targets = [
      valuesRef.current,
      prioritiesRef.current,
      quoteRef.current, // D
      statsRef.current,
      headerRef.current,
    ];

    targets.forEach((target) => {
      if (target) observer.observe(target);
    });

    return () => {
      observer.disconnect(); // Clean up observer on unmount
    };
  }, []);

  return (
    <section id="about" className="about-us">
      <div className="about-us-container">
        {/* Header */}
        <div ref={headerRef} className="about-us-header">
          <h2 className="about-us-title">Nasza wizja i wartości</h2>
          <p className="about-us-subtitle">
            Stawiamy na innowacyjność, praktyczność i
            otwartość na indywidualne potrzeby klientów. Nasze rozwiązania technologiczne są zaprojektowane, tak aby sprostać wyzwaniom nowoczesnych usług
            prawnych.
          </p>
        </div>

        {/* Quote */}
        <div ref={quoteRef} className="about-us-quote">
          <p className="about-us-quote-text">
            "Nowe technologie zmieniają oblicze branży prawniczej. Chcemy sprawić,
            żeby każda kancelaria, bez względu na jej wielkość, doświadczenie czy
            budżet, mogła w pełni wykorzystać ich potencjał."
          </p>
          <p className="about-us-quote-author">
            <span style={{display: "block"}}>Mateusz Hanak</span>
            <span style={{display: "block"}}>Prezes Encoded Legal Solutions</span>
          
          </p>
        </div>

        {/* Values */}
        <div ref={valuesRef} className="about-us-values">
          <h3 className="section-title">Nasze Wartości</h3>
          <div className="section-about-us-child-container">
            <div className="value-item">
              <h4 className="value-title">🧩 Innowacyjność</h4>
              <p className="value-description">
                Tworzymy technologie, które nie tylko usprawniają procesy prawne, ale również pozwalają spojrzeć na prawo z nowej perspektywy.
              </p>
            </div>
            <div className="value-item">
              <h4 className="value-title">🤝 Otwartość</h4>
              <p className="value-description">
                Wierzymy w równy dostęp do innowacji, nasze rozwiązania są dla każdej kancelarii, niezależnie od jej wielkości.
              </p>
            </div>
            <div className="value-item">
              <h4 className="value-title">🌍 Praktyczność</h4>
              <p className="value-description">
                Nasze narzędzia powstają z myślą o rzeczywistych potrzebach prawników, pomagając w codziennej pracy.
              </p>
            </div>
          </div>
        </div>

        {/* Priorities */}
        <div ref={prioritiesRef} className="about-us-priorities">
          <h3 className="section-title" style={{marginTop: "3.5rem"}}>Nasze Priorytety</h3>
          <div className="section-about-us-child-container">
            <div className="priority-item">
              <h4 className="priority-title">✍️ Legal Design</h4>
              <p className="priority-description">
                Kluczowy kierunek, w którym rozwijamy technologie upraszczające prawo i czyniące je bardziej przejrzystym.
              </p>
            </div>
            <div className="priority-item">
              <h4 className="priority-title">🤖 Sztuczna Inteligencja</h4>
              <p className="priority-description">
                Wspieramy pracę przez automatyzację i inteligentne rozwiązania, które usprawniają codzienne procesy.
              </p>
            </div>
            <div className="priority-item">
              <h4 className="priority-title">🛠️ Indywidualne Podejście</h4>
              <p className="priority-description">
                Integrujemy technologie z procesami Twojej kancelarii, tworząc niezawodne środowisko pracy.
              </p>
            </div>
          </div>
        </div>

        {/* Stats */}
      
      </div>
    </section>
  );
};

export default AboutUs;
