import React from 'react';
import './HeroSection.css';

import img1 from '../images/img1.jpg';


const HeroSection = () => {
  return (
    <section id="/" className="hero-section">
      <div className="hero-content">
        <h1 className="hero-title">Tworzymy przyszłość branży prawniczej!</h1>
        <p className="hero-description">
          Łączymy świat prawa i technologii, oferując oprogramowanie, które podnosi efektywność codziennej pracy
          prawników.
        </p>
        <div className="hero-buttons">
          <a href="#learn-more" className="hero-button hero-button-primary">
            Dowiedz się więcej
          </a>
          <a href="#contact" className="hero-button hero-button-secondary">
            Skontaktuj się z nami
          </a>
        </div>
      </div>
      <div className="hero-image">
      <img
          src={img1} // Use the imported image here
          alt="Corporate Excellence"
        />
      </div>
    </section>
  );
};

export default HeroSection;
