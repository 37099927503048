import React from 'react';
import Header from '../components/Header'; // The Header component we created earlier
import Hero from '../components/HeroSection';
import AboutUs from '../components/AboutUs';
import OurOffer from '../components/OurOffer';
import Portfolio from '../components/Portfolio';
import Contact from '../components/Contact';
// import Footer from '../components/Footer';

const Home = () => {
  return (
    <div style={{ backgroundColor: '#ffffff', color: '#24335c' }}>


        <Hero />
        <AboutUs />
        <OurOffer />
        <Portfolio />
        <Contact />



    </div>
  );
};

export default Home;
