import React, { useState } from 'react';
import './Header.css'; // Import pliku CSS

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const target = document.querySelector(targetId);
    if (target) {
      window.scrollTo({
        top: target.offsetTop - 80, // Ustaw offset w zależności od wysokości nagłówka
        behavior: 'smooth',
      });
      setIsMenuOpen(false); // Zamknięcie menu po kliknięciu w link
    }
  };

  return (
    <header className="header">
      <a href="/" className="logo">
        ELS
      </a>
      <button
        className={`hamburger ${isMenuOpen ? 'hamburger--open' : ''}`}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>
      <nav className={`nav ${isMenuOpen ? 'nav--open' : ''}`}>
        <ul className="nav-list">
          <li>
            <a href="/" className="nav-link">
              Strona główna
            </a>
          </li>
          <li>
            <a
              href="/#about"
              className="nav-link"
              onClick={(e) => handleSmoothScroll(e, '#about')}
            >
              O nas
            </a>
          </li>
          <li>
            <a
              href="/#services"
              className="nav-link"
              onClick={(e) => handleSmoothScroll(e, '#services')}
            >
              Usługi
            </a>
          </li>
          <li>
            <a
              href="/#portfolio"
              className="nav-link"
              onClick={(e) => handleSmoothScroll(e, '#portfolio')}
            >
              Portfolio
            </a>
          </li>
          <li>
            <a
              href="/#contact"
              className="nav-link"
              onClick={(e) => handleSmoothScroll(e, '#contact')}
            >
              Kontakt
            </a>
          </li>
          {/* <li>
            <a href="/blog" className="nav-link">
              Blog
            </a>
          </li> */}
          <li>
            <a href="/legal" className="nav-link">
              Kwestie prawne
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
