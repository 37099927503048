import React, { useEffect, useRef, useState } from 'react';
import './Contact.css';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

const Contact = () => {
  const headerRef = useRef(null);
  const subtitleRef = useRef(null);
  const formRef = useRef(null);
  const infoRef = useRef(null);

  // State for form fields and validation
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    privacy: false,
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Update form validation state
  useEffect(() => {
    const { name, email, message, privacy } = formData;
    setIsFormValid(
      name.trim() &&
      email.trim() &&
      message.trim() &&
      privacy &&
      captchaValue !== null
    );
  }, [formData, captchaValue]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle CAPTCHA change
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const dataToSend = {
      ...formData,
      'g-recaptcha-response': captchaValue,
    };

    try {
      await axios.post(
        'https://us-central1-your-project-id.cloudfunctions.net/sendEmail',
        dataToSend
      );
      alert('Wiadomość została wysłana!');
      setFormData({
        name: '',
        email: '',
        message: '',
        privacy: false,
      });
      setCaptchaValue(null);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Wystąpił błąd podczas wysyłania wiadomości.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Intersection Observer for animations (unchanged)
  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.2,
    });

    [headerRef.current, subtitleRef.current, formRef.current, infoRef.current].forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section id="contact" className="contact">
      <div className="contact-container">
        {/* Section Header */}
        <div className="contact-header">
          <h2 ref={headerRef} className="contact-title">
            Skontaktuj się z nami
          </h2>
          <p ref={subtitleRef} className="contact-subtitle">
            Masz pytania lub chcesz dowiedzieć się więcej o naszych rozwiązaniach? Skontaktuj się
            z nami za pomocą formularza poniżej.
          </p>
        </div>

        {/* Form and Information */}
        <div className="contact-content">
          {/* Contact Form */}
          <form ref={formRef} onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="name" className="form-label">
                Twoje imię
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Wpisz swoje imię"
                required
                className="form-input"
                value={formData.name}
                onChange={handleInputChange}
                disabled={isSubmitting}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Twój email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Wpisz swój email"
                required
                className="form-input"
                value={formData.email}
                onChange={handleInputChange}
                disabled={isSubmitting}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message" className="form-label">
                Twoja wiadomość
              </label>
              <textarea
                id="message"
                name="message"
                rows="5"
                placeholder="Napisz tutaj swoją wiadomość"
                required
                className="form-textarea"
                value={formData.message}
                onChange={handleInputChange}
                disabled={isSubmitting}
              ></textarea>
            </div>
            <div className="form-group privacy-group">
              <input
                type="checkbox"
                id="privacy"
                name="privacy"
                required
                className="form-checkbox"
                checked={formData.privacy}
                onChange={handleInputChange}
                disabled={isSubmitting}
              />
              <label htmlFor="privacy" className="form-privacy-label">
                Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z{' '}
                <a href="#privacy-policy" className="form-privacy-link">
                  polityką prywatności
                </a>
                .
              </label>
            </div>
            {/* reCAPTCHA */}
            <div className="form-group">
              {/* <ReCAPTCHA
                sitekey="YOUR_RECAPTCHA_SITE_KEY"
                onChange={handleCaptchaChange}
                theme="light"
              /> */}
            </div>
            <button
              type="submit"
              className="form-button"
              disabled={!isFormValid || isSubmitting}
            >
              {isSubmitting ? 'Wysyłanie...' : 'Wyślij'}
            </button>
          </form>

          {/* Contact Information */}
          <div ref={infoRef} className="contact-info">
            <h3 className="contact-info-title">Dane adresowe</h3>
            <p className="contact-info-address">
              ul. Augustyna Szamarzewskiego 21/2<br />
              60-514 Poznań
            </p>
            <p className="contact-info-details">
              <strong>KRS:</strong> 0001106943 | <strong>NIP:</strong> 7812070483 |{' '}
              <strong>REGON:</strong> 528696240
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
