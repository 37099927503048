import React from 'react';
import './Footer.css'; // Import pliku CSS

const Footer = () => {
  
  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const target = document.querySelector(targetId);
    if (target) {
      window.scrollTo({
        top: target.offsetTop - 80, // Ustaw offset w zależności od wysokości nagłówka
        behavior: 'smooth',
      });
      // Zamknięcie menu po kliknięciu w link
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo i krótki opis */}
        <div className="footer-about">
          <h3 className="footer-logo">Encoded Legal Solutions P.S.A.</h3>
          <p className="footer-description">
            Wspieramy firmy i kancelarie prawnicze innowacyjnymi rozwiązaniami, które upraszczają złożoność codziennej pracy.
          </p>
        </div>

        {/* Linki nawigacyjne */}
        <div className="footer-links">
          <a href="/" className="footer-link">Strona główna</a>
          <a href="/#about" className="footer-link" onClick={(e) => handleSmoothScroll(e, '#about')}>O nas</a>
          <a href="/#services" className="footer-link" onClick={(e) => handleSmoothScroll(e, '#services')}>Usługi</a>
          <a href="/#portfolio" className="footer-link" onClick={(e) => handleSmoothScroll(e, '#portfolio')}>Portfolio</a>
          <a href="/#contact" className="footer-link"   onClick={(e) => handleSmoothScroll(e, '#contact')}>Kontakt</a>
          {/* <a href="/blog" className="footer-link">Blog</a> */}
          <a href="/legal" className="footer-link">Kwestie prawne</a>
        </div>

        {/* Informacje dodatkowe */}
        <div className="footer-info">
          <p className="footer-rights">
            &copy; 2024 Encoded Legal Solutions P.S.A. Wszelkie prawa zastrzeżone.
          </p>
          <p className="footer-privacy">
            <a href="#privacy-policy" className="footer-privacy-link">Polityka prywatności</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
