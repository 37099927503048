import React from 'react';
import './Legal.css'; // Plik CSS dopasowany do sekcji

const Legal = () => {
  return (
    <section id="legal" className="legal">
      <div className="legal-container">
        {/* Nagłówek sekcji */}
        <div className="legal-header animate">
          <h2 className="legal-title">Kwestie prawne</h2>
          <p className="legal-subtitle">
            Dbamy o przejrzystość i zgodność z przepisami prawa w każdym aspekcie naszej działalności.
          </p>
        </div>

        {/* Treść sekcji */}
        <div className="legal-content">
          {/* Polityka prywatności */}
          <div className="legal-section">
            <h3 className="legal-section-title">Polityka Prywatności (RODO)</h3>
            <p className="legal-section-text">
              Twoja prywatność jest dla nas priorytetem. W naszej Polityce Prywatności znajdziesz szczegółowe informacje na temat:
            </p>
            <ul className="legal-list">
              <li>Zakresu przetwarzania danych osobowych,</li>
              <li>Praw przysługujących użytkownikom,</li>
              <li>Stosowanych środków bezpieczeństwa w celu ochrony danych.</li>
            </ul>
           
            <p className="legal-section-text">
              Pobierz naszą Politykę Prywatności w formacie PDF:
            </p>
            <a
              href="/privacy-policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="legal-link legal-button"
            >
              Pobierz Politykę Prywatności
            </a>
          </div>

          {/* DPA */}
          <div className="legal-section">
  <h3 className="legal-section-title">Data Processing Agreement (DPA)</h3>
  <p className="legal-section-text">
    Nasza Umowa Powierzenia Przetwarzania Danych (DPA) stanowi gwarancję zgodności z przepisami RODO oraz zapewnia najwyższe standardy ochrony danych osobowych.  Każdorazowo zawieramy DPA z naszymi partnerami, w których szczegółowo określamy m.in.:
  </p>
  <p className="legal-section-text">
   
  </p>
  <ul className="legal-list">
    <li>
      <strong>Szczegółowy zakres i cel przetwarzania danych:</strong> Dane są przetwarzane wyłącznie w celach uzgodnionych z klientem i zgodnych z obowiązującymi przepisami.
    </li>
    <li>
      <strong>Gwarancje bezpieczeństwa i poufności:</strong> Stosujemy zaawansowane technologie szyfrowania i kontroli dostępu, aby chronić dane osobowe przed nieautoryzowanym dostępem.
    </li>
    <li>
      <strong>Informacje o podwykonawcach:</strong> Współpracujemy wyłącznie z zaufanymi podmiotami spełniającymi nasze rygorystyczne wymagania dotyczące bezpieczeństwa i poufności danych, takimi jak:
      <ul className="legal-sublist" style={{lineHeight: "1.6"}}>
        <li><strong>Google Ireland Limited:</strong> Bezpieczne przechowywanie i zarządzanie danymi.</li>
        <li><strong>OpenAI Ireland Limited:</strong> Przetwarzanie danych w oparciu o sztuczną inteligencję.</li>
        <li><strong>Anthropic Ireland Limited:</strong> Przetwarzanie danych w oparciu o sztuczną inteligencję.</li>
      </ul>
    </li>
  </ul>
  <p className="legal-section-text">
    Zapewniamy, że przekazywanie wrażliwych danych do naszych partnerów odbywa się zgodnie z najlepszymi praktykami bezpieczeństwa oraz pod ścisłą kontrolą.
  </p>
</div>


          {/* Polityka AI */}
          <div className="legal-section">
            <h3 className="legal-section-title">Nasza Polityka w Zakresie AI</h3>
            <p className="legal-section-text">
              Zdajemy sobie sprawę z potencjału sztucznej inteligencji, ale również z odpowiedzialności, która się z tym wiąże. Dlatego nasza polityka w zakresie AI obejmuje:
            </p>
            <ul className="legal-list">
              <li>Zapewnienie pełnej transparentności w zakresie działania naszych narzędzi AI,</li>
              <li>Używanie danych użytkowników wyłącznie w celach poprawy jakości usług,</li>
              <li>Ograniczenie przechowywania danych do minimalnego wymaganego okresu,</li>
              <li>Zapewnienie etycznego wykorzystania algorytmów AI.</li>
            </ul>
            <p className="legal-section-text">
              Aby dowiedzieć się więcej, pobierz naszą Politykę AI w formacie PDF:
            </p>
            <a
              href="/ai-policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="legal-link legal-button"
            >
              Pobierz Politykę AI
            </a>
          </div>

          {/* Kontakt */}
          <div className="legal-section">
            <h3 className="legal-section-title">Kontakt w sprawach prawnych</h3>
            <p className="legal-section-text">
              Jeśli masz pytania dotyczące naszych umów, zasad przetwarzania danych lub polityki AI, skontaktuj się z nami przez formularz kontaktowy.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Legal;
